<template>
	<div class="goodsdetailbox">
		<div class="head">
			<div class="imgbox">
				<img class="h-full" v-if="detaildata.image" :src="detaildata.image" alt="" />
				<div class="msk" v-if="detaildata.stocks == 0">
					<img class="mskicon" src="../../assets/images/pc-sellout.png" alt="" />
				</div>
			</div>
			<div class="infobox">
				<img src="@/assets/images/pc-detailtype.png" alt="" class="typeimg" />
				<div class="infoname">{{ detaildata.title }}</div>
				<div class="star-box"></div>
				<div class="pricebox">
					<div class="price">$ {{ detaildata.marketprice * 1 < detaildata.price * 1 ? detaildata.price * 1.5 : detaildata.marketprice }}</div>
					<div class="vip-price">$ {{ detaildata.price }}</div>
				</div>
				<div class="li-list-box">
					<div class="li">Lifetime License – One-Time Payment</div>
					<div class="li">Original/Genuine License</div>
					<div class="li">Multilingual – All languages supported</div>
				</div>
				<div class="pay-box">
					<div class="icon"></div>
					<div class="text">One-time payment</div>
				</div>
				<div class="num-box">
					<div class="changenum">
						<div class="ko sub" @click="changenum('-')">
							<img src="../../assets/images/pc-detail-sub.png" alt="" />
						</div>
						<input type="text" class="numbox" v-model="totalnum" @blur="numsize" />
						<div class="ko add" @click="changenum('+')">
							<img src="../../assets/images/pc-detail-add.png" alt="" />
						</div>
					</div>
					<div class="Stockbox">Stock: {{ detaildata.stocks }}</div>
				</div>

				<div class="btnbox">
					<div class="btn b1" @click="checkOutOnClick">Check out</div>
					<div class="btn b2" @click="addToCarOnClick(detaildata, totalnum)">Add to cart</div>
				</div>
				<div class="email-box">
					<div class="icon"></div>
					<div class="text">Instant Delivery to your Email</div>
				</div>
				<!-- <div class="jump" @click="scrollToAnchor">Product description link, click to jump below</div> -->
			</div>
		</div>
		<div class="ProductDetails">
			<div @click="changeTabs(index)" class="li point" :class="{ 'li-active': index === activeIndex }" v-for="(item, index) in ['Description', 'Reviews (0)']" :key="item">{{ item }}</div>
		</div>
		<div class="content" ref="target">
			<div v-if="activeIndex === 0" class="center" v-html="detaildata.content"></div>
		</div>
	</div>
</template>

<script>
	import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
	import { getGoodsdetailApi, addCarApi } from '../../network/api';
	export default {
		data() {
			return {
				detaildata: {
					title: '',
					image: '',
					price: null,
					stocks: null,
					content: ''
				},
				totalnum: 1, //默认购买数量
				goodsid: null,
				localCarList: [],
				activeIndex: 0
			};
		},
		created() {
			this.goodsid = this.$route.query.id;
			this.localCarList = this.carList || [];
			this.getGoodsdetaildata();
		},
		computed: {
			...mapGetters(['isLogin']),
			...mapState(['userInfo', 'imgbaseUrl', 'carList', 'homeProductList']),
		},

		// mounted() {
		// 	window.scrollTo({
		// 		top: 500
		// 		// behavior: 'smooth' // 为了实现平滑滚动效果
		// 	});
		// },
		methods: {
			...mapMutations(['setCarList']),
			...mapActions(['ReFreshUserInfo', 'getCartList']),
			changeTabs(index) {
				this.activeIndex = index;
			},
			async checkOutOnClick() {
				await this.addToCarOnClick(this.detaildata, this.totalnum);
				this.$router.push('/cartDetail');
			},
			async addToCarOnClick(item, inNum = 1) {
				function addSuccess() {
					document.querySelectorAll('.float-cart')[0].style.height = '4.9rem';
					// setTimeout(() => {
					// 	document.querySelectorAll('.float-cart')[0].style.height = '0rem';
					// }, 1000);
				}
				console.log(123, item);
				if (this.isLogin) {
					const params = {
						goods_id: item.id,
						nums: inNum
					};
					try {
						const res = await addCarApi(params);
						console.log('加入购物车结果', res);
						this.$message.success('Added successfully');
						addSuccess();
						this.getCartList();
					} catch (error) {}
				} else {
					const ids = this.localCarList.map((wItem) => wItem.goods_id);
					console.log(12345, this.carList);
					if (!ids.includes(item.id)) {
						this.localCarList.push({
							goods: item,
							nums: inNum,
							goods_id: item.id
						});
					} else {
						this.localCarList.forEach((vItem) => {
							if (vItem.goods_id === item.id) {
								vItem.nums = vItem.nums * 1 + inNum * 1;
							}
						});
					}
					this.setCarList(this.localCarList);
					console.log('加入购物车结果', this.localCarList);
					this.$message.success('Added successfully');
					addSuccess();
				}
			},
			getGoodsdetaildata() {
				let params = {
					id: this.goodsid
				};
				getGoodsdetailApi(params).then((res) => {
					console.log(res.data);
					this.detaildata = res.data;
					scrollTo({
						top: 0,
						behavior: 'smooth' // 为了实现平滑滚动效果
					});
				});
			},
			scrollToAnchor() {
				this.$refs.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
			},
			changenum(v) {
				if (v == '-') {
					if (this.totalnum < 2) {
						this.$message({
							message: 'Minimum purchase of one item!',
							type: 'error'
						});
						this.totalnum = 1;
					} else {
						this.totalnum = this.totalnum - 1;
					}
				} else if (v == '+') {
					if (this.totalnum >= 100) {
						this.$message({
							message: 'Single purchase limit of 100 pieces!',
							type: 'error'
						});
					} else {
						this.totalnum = this.totalnum - 0 + 1;
					}
				}
			},
			numsize() {
				if (this.totalnum <= 0) {
					this.$message({
						message: 'Minimum purchase of one item!',
						type: 'error'
					});
					this.totalnum = 1;
				} else if (this.totalnum >= 100) {
					this.$message({
						message: 'Single purchase limit of 100 pieces!',
						type: 'error'
					});
					this.totalnum = 100;
				}
			}
		},
		watch: {
			$route: {
				handler(newVal) {
					this.goodsid = newVal.query.id;
					this.getGoodsdetaildata();
				},
				deep: true,
				immediate: true
			}
		}
	};
</script>

<style lang="scss" scoped>
	.goodsdetailbox {
		width: 100%;
		padding: 0.6rem 3.4rem;
		box-sizing: border-box;
		.head {
			width: 100%;
			margin: 0 auto;
			// padding: 0.6rem 0 1rem 0;
			padding-left: 0.3rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			margin-bottom: 0.8rem;
			.imgbox {
				width: 4.72rem;
				height: 4.72rem;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0.6rem;
				position: relative;
				.msk {
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					background: rgba(0, 0, 0, 0.4);
					transform: scale(1.1);
					.mskicon {
						position: absolute;
						bottom: 0.2rem;
						right: 0.2rem;
						width: 1.03rem;
						height: 1.03rem;
					}
				}
			}
			.infobox {
				// height: 4rem;
				width: 100%;
				.typeimg {
					width: 1.22rem;
					height: 0.4rem;
					margin-bottom: 0.1rem;
				}
				.infoname {
					font-weight: 500;
					font-size: 0.32rem;
					color: #333333;
					margin-bottom: 0.24rem;
				}
				.star-box {
					width: 1.4rem;
					height: 0.22rem;
					background: url('~@/assets/images/star-bg.png') no-repeat;
					background-size: 100% 100%;
					margin-bottom: 0.2rem;
				}
				.pricebox {
					display: flex;
					align-items: center;
					margin-bottom: 0.22rem;
					.price {
						font-weight: 500;
						font-size: 0.24rem;
						color: #999999;
						margin-right: 0.2rem;
						text-decoration: line-through;
					}
					.vip-price {
						font-weight: 500;
						font-size: 0.36rem;
						color: #333333;
					}
				}
				.li-list-box {
					font-weight: 400;
					font-size: 0.14rem;
					color: #666666;
					margin-bottom: 0.1rem;
					.li {
						padding-left: 0.2rem;
						line-height: 0.28rem;
						position: relative;
					}
					.li::before {
						content: '';
						position: absolute;
						left: 0.05rem;
						top: 50%;
						transform: translateY(-50%);
						width: 0.05rem;
						height: 0.05rem;
						background: #666;
						border-radius: 50%;
					}
				}
				.pay-box {
					display: flex;
					align-items: center;
					margin-bottom: 0.26rem;
					.icon {
						width: 0.18rem;
						height: 0.18rem;
						margin-right: 0.06rem;
						background: url('~@/assets/images/pay-bg.png') no-repeat;
						background-size: 100% 100%;
					}
					.text {
						font-weight: 400;
						font-size: 0.14rem;
						color: #666666;
					}
				}
				.num-box {
					display: flex;
					align-items: center;
					width: 100%;
					margin-bottom: 0.2rem;
					.changenum {
						width: 0.96rem;
						height: 0.26rem;
						flex-shrink: 0;
						display: flex;

						margin-right: 0.15rem;
						.ko {
							width: 0.24rem;
							height: 0.26rem;
							display: flex;
							justify-content: center;
							align-items: center;
							border: 0.01rem solid #b3cddb;
							cursor: pointer;
							img {
								width: 0.16rem;
								height: 0.16rem;
							}
						}

						.numbox {
							width: 0.48rem;
							height: 0.26rem;
							border: 0.01rem solid #b3cddb;
							font-weight: 400;
							font-size: 0.12rem;
							text-align: center;
							color: #333333;
							padding: 0;
							border-left: 0;
							border-right: 0;
						}
					}
					.Stockbox {
						font-weight: 400;
						font-size: 0.14rem;
						color: #666666;
					}
				}

				.btnbox {
					// width: 3.32rem;
					display: flex;
					// justify-content: space-between;
					margin-bottom: 0.2rem;
					.btn {
						width: 1.22rem;
						height: 0.32rem;
						text-align: center;
						font-weight: 500;
						font-size: 0.14rem;
						line-height: 0.32rem;
					}
					.b1 {
						background: #4182a4;
						color: #ffffff;
						cursor: pointer;
						margin-right: 0.08rem;
					}
					.b2 {
						background: rgba(65, 130, 164, 0.2);
						color: #4182a4;
						cursor: pointer;
					}
				}
				.email-box {
					display: flex;
					align-items: center;
					margin-bottom: 0.26rem;
					.icon {
						width: 0.18rem;
						height: 0.18rem;
						margin-right: 0.06rem;
						background: url('~@/assets/images/email-bg.png') no-repeat;
						background-size: 100% 100%;
					}
					.text {
						font-weight: 400;
						font-size: 0.14rem;
						color: #666666;
					}
				}
				.jump {
					margin-top: 0.24rem;
					font-weight: 400;
					font-size: 0.12rem;
					line-height: 0.12rem;
					color: #4182a4;
					text-align: left;
					text-decoration-line: underline;
					cursor: pointer;
				}
			}
		}
		.ProductDetails {
			border-top: 0.02rem solid #cccccc;
			// padding-top: 0.22rem;
			display: flex;
			margin-bottom: 0.3rem;
			// width: 100%;
			// padding: 0 3.6rem;
			// box-sizing: border-box;
			// height: 0.84rem;
			// // background: #7bb9d9;
			// display: flex;
			// // justify-content: center;
			// // padding-left: 0.4rem;
			// align-items: center;
			// font-weight: 500;
			// font-size: 0.24rem;
			// color: #ffffff;
			.li {
				font-weight: bold;
				font-size: 0.18rem;
				color: #333333;
				margin-right: 0.4rem;
				padding-top: 0.22rem;
				border-top: 0.07rem solid rgba(0, 0, 0, 0);
				margin-top: -0.02rem;
			}
			.li-active {
				border-top: 0.07rem solid #4182a4;
			}
		}
		.content {
			width: 100%;
			.center {
				width: 12rem;
				margin: 0 auto;
				background: #f4f6f9 !important;
				padding: 0.3rem;
			}
		}
	}
</style>
